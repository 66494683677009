import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ServiceUtils} from '../utils/service.utils';
import {catchError} from 'rxjs/operators';

export class GenericReadOnlyService<T> {
  constructor(private httpClient: HttpClient, private serviceName: string) {}

  findAll(): Observable<T[]> {
    return this.httpClient.get<T[]>(ServiceUtils.getApiUrl(this.serviceName)).pipe(catchError(this.handleError));
  }
  findById(id: any): Observable<T> {
    return this.httpClient.get<T>(ServiceUtils.getApiUrl(this.serviceName, [id]));
  }

  private handleError(err: HttpErrorResponse | any) {
    console.error('An error occurred', err);
    return throwError(err.message || err);
  }
}


