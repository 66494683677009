import {Injectable} from '@angular/core';
import {ProbabilisticIntenseForecastModel} from '../model/probabilistic-intense-forecast.model';
import {GenericService} from './generic.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Moment} from 'moment/moment';
import {Observable} from 'rxjs';
import {ServiceUtils} from '../utils/service.utils';

@Injectable({
  providedIn: 'root',
})
export class ProbabilisticBulletinService extends GenericService<ProbabilisticIntenseForecastModel> {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'probabilistic-forecast');
  }

  // findByDates(startDate: Moment, endDate: Moment): Observable<ProbabilisticIntenseForecastModel> {
  //   const params = new HttpParams()
  //     .append('start', startDate.toISOString())
  //     .append('end', endDate.toISOString());
  //
  //   return this.httpClient.get<ProbabilisticIntenseForecastModel>(
  //     ServiceUtils.getApiUrl(this.serviceName, ['find']), { params: params },
  //   );
  // }

  findByCreationDate(creationDate: Moment): Observable<ProbabilisticIntenseForecastModel> {
    const params = new HttpParams()
      .append('creationDate', creationDate.format('YYYY-MM-DD'));

    return this.httpClient.get<ProbabilisticIntenseForecastModel>(
      ServiceUtils.getApiUrl(this.serviceName, ['find']), { params: params },
    );
  }
}
