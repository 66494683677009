import {Injectable, OnDestroy} from '@angular/core';
import {User, UserManager, WebStorageStateStore} from 'oidc-client-ts';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, fromEvent, interval, merge, Observable, Subscription} from 'rxjs';
import {bufferTime, distinctUntilChanged, filter, map, take} from 'rxjs/operators';
import {TndUserManagerSettings} from '../model/tnd-user-manager-settings.model';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OidcAuthService implements OnDestroy {
  refreshTokenSubject: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(null);
  utenteSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  user$: Observable<User | null> = this.utenteSubject.asObservable().pipe(
    filter((user) => user !== null),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
  );

  private userManager: UserManager;
  private user: User | null;
  private isRefreshing: boolean;
  private activitySubscription: Subscription = null;

  constructor(
    private router: Router,
  ) {
    // const settings = {
    //   authority: environment.security.oidc.authority,
    //   client_id: environment.security.oidc.clientId,
    //   redirect_uri: environment.security.oidc.authorize.redirectUri,
    //   // silent_redirect_uri: `${window.location.origin}/silent-callback.html`,
    //   post_logout_redirect_uri: `${window.location.origin}`,
    //   response_type: 'code',
    //   scope: environment.security.oidc.authorize.scope,
    // };
    //
    // this.userManager = new UserManager(settings);

    this.isRefreshing = false;
    this.user = null;
    this.initUserManager();
  }

  ngOnDestroy() {
    this.destroy();
  }

  destroy() {
    if (this.activitySubscription) {
      this.activitySubscription.unsubscribe();
      this.activitySubscription = null;
    }
  }

  public async login(): Promise<void> {
    try {
      await this.userManager.signinRedirect();
    } catch (error) {
      console.error('Errore durante il reindirizzamento al login:', error);
      throw error;
    }
  }

  public async completeAuthentication(): Promise<User | null> {
    try {
      return await this.userManager.signinRedirectCallback();
    } catch (error) {
      console.error('Errore durante il completamento dell\'autenticazione:', error);
      throw error;
    }
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public async logout(url?: string): Promise<void> {
    try {
      let options = {};

      if (url) {
        options = {
          ...options,
          url: url,
        };
      }

      await this.userManager.signoutRedirect()
        .then(() => {
          localStorage.clear();
          this.router.navigate(['/auth/login', options]);
        });
    } catch (error) {
      console.error('Errore durante il logout:', error);
      throw error;
    }
  }

  public async isAuthenticated(): Promise<boolean> {
    const user = await this.getUser();
    return !!user && !user.expired;
  }

  // NEW
  async checkAndInitiateAuth() {
    try {
      const isAuthenticated = await this.isAuthenticated();
      if (!isAuthenticated) {
        await this.login();
      }
    } catch (error) {
      console.error('Errore durante il controllo dell\'autenticazione:', error);
      // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    }
  }

  public checkSession() {
    this.refreshTokenSubject.next('');
    this.isLoggedIn().then((isLogged: boolean) => {
      if (isLogged) {
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          const token = this.user?.refresh_token;
          if (token)
            this.refresh().then((user: User | null) => {
              this.user = user;
              this.isRefreshing = false;
              this.refreshTokenSubject.next(user?.access_token);
              this.utenteSubject.next(this.user);
            }).catch(() => {
              this.isRefreshing = false;
              sessionStorage.setItem('redirect', this.router.url);
              // this.reindirizzamentoService.vaiAllaPaginaAutenticazione();
              this.checkAndInitiateAuth();
            });
        }
      } else {
        sessionStorage.setItem('redirect', this.router.url);
        // this.reindirizzamentoService.vaiAllaPaginaAutenticazione();
        this.checkAndInitiateAuth();
      }
    });
  }

  authenticationToken(): string {
    return this.user == null ? '' : this.user?.access_token;
  }

  refresh(): Promise<User | null> {
    this.getUserManager();
    return this.userManager.signinSilent();
  }

  getUserManager() {
    if (!this.userManager) {
      this.initUserManager();
    }
    this.userManager.getUser().then((user: User | null) => {
      this.user = user;
    });
  }

  initUserManager() {
    const userManagerSettings = new TndUserManagerSettings();
    userManagerSettings.authority = environment.security.oidc.authority;
    userManagerSettings.client_id = environment.security.oidc.clientId;
    userManagerSettings.response_type = 'code';
    userManagerSettings.scope = environment.security.oidc.authorize.scope;
    userManagerSettings.redirect_uri = environment.security.oidc.authorize.redirectUri;
    userManagerSettings.post_logout_redirect_uri = `${window.location.origin}`;
    userManagerSettings.automaticSilentRenew = false;
    userManagerSettings.validateSubOnSilentRenew = false;
    userManagerSettings.includeIdTokenInSilentRenew = false;
    userManagerSettings.loadUserInfo = true;  // chiamata a getUserInfo di Aac
    userManagerSettings.userStore = new WebStorageStateStore({
      store: window.sessionStorage,
    });
    this.userManager = new UserManager(userManagerSettings);
  }

  isLoggedIn(): Promise<boolean> {
    this.initUserManager();
    return this.userManager.getUser().then((user) => {
      this.user = user;
      if (this.user == null || this.user === undefined) {
        this.user = null;
        return false;
      }
      this.utenteSubject.next(this.user);
      return true;
    }).catch((err) => {
      return false;
    });
  }

  async checkAuth() {
    try {
      await this.completeAuthentication();
      this.router.navigate(['/']);
    } catch (error) {
      console.error('Errore durante il completamento dell\'autenticazione:', error);
      this.logout();
    }
  }

  startObservingActivity() {
    const userActivity$ = merge(
      fromEvent(document, 'click'),
      fromEvent(document, 'scroll'),
      fromEvent(document, 'mousemove'),
    ).pipe();

    const activityCheckInterval$ = interval(environment.intervalPeriod);

    this.activitySubscription = activityCheckInterval$.pipe(
      map(() => userActivity$.pipe(
        bufferTime(environment.bufferTime),
        take(1),
      )),
    ).subscribe(activities => {
      activities.subscribe(events => {
        if (events.length > 0) {
          // this.refresh().subscribe(value => console.log(value));
          console.log('interactions: ', events);
          this.checkSession();
        } else if (events.length <= 0) {
          console.log('no interactions');

          this.isAuthenticated()
            .then(isTokenExpired => {
              if (isTokenExpired) {
                console.log('token expired. logging off');
                this.logout(this.router.url);
              }
            });
        }
      });
    });
  }
}
