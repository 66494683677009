import { OidcMetadata, PopupWindowFeatures, SigningKey, StateStore, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

export class TndUserManagerSettings implements UserManagerSettings {
  popup_redirect_uri?: string;
  popup_post_logout_redirect_uri?: string;
  popupWindowFeatures?: PopupWindowFeatures;
  popupWindowTarget?: string;
  redirectMethod?: 'replace' | 'assign';
  iframeNotifyParentOrigin?: string;
  iframeScriptOrigin?: string;
  silent_redirect_uri?: string;
  silentRequestTimeoutInSeconds?: number;
  automaticSilentRenew?: boolean;
  validateSubOnSilentRenew?: boolean;
  includeIdTokenInSilentRenew?: boolean;
  monitorSession?: boolean;
  monitorAnonymousSession?: boolean;
  checkSessionIntervalInSeconds?: number;
  query_status_response_type?: string;
  stopCheckSessionOnError?: boolean;
  revokeTokenTypes?: ('access_token' | 'refresh_token')[];
  revokeTokensOnSignout?: boolean;
  accessTokenExpiringNotificationTimeInSeconds?: number;
  userStore?: WebStorageStateStore;
  authority!: string;
  metadataUrl?: string;
  metadata?: Partial<OidcMetadata>;
  metadataSeed?: Partial<OidcMetadata>;
  signingKeys?: SigningKey[];
  client_id!: string;
  client_secret?: string;
  response_type?: string;
  scope?: string;
  redirect_uri!: string;
  post_logout_redirect_uri?: string;
  client_authentication?: 'client_secret_basic' | 'client_secret_post';
  prompt?: string;
  display?: string;
  max_age?: number;
  ui_locales?: string;
  acr_values?: string;
  resource?: string;
  response_mode?: 'query' | 'fragment';
  filterProtocolClaims?: boolean;
  loadUserInfo?: boolean;
  staleStateAgeInSeconds?: number;
  clockSkewInSeconds?: number;
  userInfoJwtIssuer?: string;
  mergeClaims?: boolean;
  stateStore?: StateStore;
  extraQueryParams?: Record<string, string | number | boolean>;
  extraTokenParams?: Record<string, unknown>;
}
