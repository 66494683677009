import {Component, OnInit} from '@angular/core';
import {NbToastrService, NbWindowRef} from '@nebular/theme';
import {PublishService} from '../../../@core/service/publish.service';
import {PublishModel} from '../../../@core/model/publish.model';
import {ForegroundTypeEnum} from '../../../@core/enums/foreground-type.enum';
import * as _ from 'lodash';
import * as moment from 'moment';
import {PublishDataModel} from '../../../@core/model/publish-data.model';
import {PublishDataDetailModel} from '../../../@core/model/publish-data-detail.model';

@Component({
  templateUrl: 'publish-modal.component.html',
  styleUrls: ['publish-modal.component.scss'],
})
export class PublishModalComponent implements OnInit {
  loading = false;

  publishModel: PublishModel = { publishDataList: [] };
  publishingDate: Date = new Date();
  minPublishingDate: Date = moment(this.publishingDate).subtract(1, 'days').toDate();

  constructor(
    public windowRef: NbWindowRef,
    private publishService: PublishService,
    private toastrService: NbToastrService,
  ) {
  }

  ngOnInit() {
    this.publishService.findOne()
      .subscribe((value: PublishModel) => {
        value.publishDataList.forEach((publishData: PublishDataModel) => {
          _.sortBy(
            publishData.dataList, [e => moment(e.start)],
          );
        });

        this.publishModel = value;
        this.initializeCheckedStates();
      });
  }

  initializeCheckedStates() {
    const format = 'HH:mm:ss';
    const now = moment();
    const partialPublishTime = moment('09:00:00', format);

    this.publishModel.publishDataList.forEach(publishData => {
      publishData.dataList.forEach((data: PublishDataDetailModel) => {
        data.publish = false;

        if (!this.disabled(data)) {
          if (now.isAfter(partialPublishTime)) {
            data.publish = true;
          } else {
            const futureDate = now.clone().add(2, 'day');
            data.publish = moment(data.start).isSameOrBefore(futureDate);
          }
        }
      });
    });
  }

  disabled(data: PublishDataDetailModel) {
    return data.id == null;
  }

  checked(data: PublishDataDetailModel) {
    return data.publish;
  }

  saveData($event) {
    this.loading = true;

    // const publishingModel: PublishModel = new PublishModel();
    // Object.keys(this.publishing).forEach((key: string, index: number) => {
    //   const keySplit: string[] = key.split('_');
    //   const foregroundType = keySplit[0];
    //   const dateStart = keySplit[1];
    //
    //   // let publishData: PublishDataModel = _.find(publishingModel.publishDataList, {
    //   //   foregroundType: foregroundType,
    //   // });
    //   //
    //   // if (!publishData) {
    //   //   publishData = {
    //   //     foregroundType: foregroundType,
    //   //     dataList: [],
    //   //   } as PublishDataModel;
    //   //
    //   //   publishingModel.publishDataList.push(publishData);
    //   // }
    //
    //   const publishData: PublishDataModel = _.find(this.publishModel.publishDataList, {
    //     foregroundType: foregroundType,
    //   });
    //
    //   if (publishData) {
    //     const publishDataDetail: PublishDataDetailModel = _.find(publishData.dataList, {
    //       start: moment(dateStart),
    //     });
    //
    //     if (publishDataDetail) {
    //       publishDataDetail.publish = this.publishing[key];
    //     }
    //   }
    // });


    this.publishModel['publishingDate'] = this.publishingDate;

    this.publishService.create(this.publishModel).subscribe(value => {
        this.toastrService.success(
          'I dati sono stati pubblicati correttamente',
          'Pubblicazione avvenuta',
          {
            duration: 5000,
          },
        );

        this.loading = false;
        this.windowRef.close();
      },
      error => {
        this.toastrService.danger(
          error.message,
          'Errore',
          {
            duration: 5000,
          },
        );

        this.loading = false;
      },
    );
  }

  reset($event): void {
    this.ngOnInit();
    this.windowRef.close();
  }

  protected readonly ForegroundTypeEnum = ForegroundTypeEnum;
  protected readonly moment = moment;
}
