import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable()
export class ServiceUtils {

  constructor() {}

  static getApiUrl(serviceName: string, nextPath: any[] = []) {
    const paths =  nextPath?.length > 0 ? `/${nextPath.filter(Boolean).join('/')}` : '';
    return `${environment.api.domain}${environment.api.context}/${serviceName}${paths}`;
  }

  static getSecurityUrl() {
    return {
      login: `${environment.security.domain}${environment.security.context}/jwt/protected/login`,
      logout: `${environment.security.domain}${environment.security.context}/jwt/protected/logout`,
      ping: `${environment.security.domain}${environment.security.context}/jwt/protected/ping`,
      refresh: `${environment.security.domain}${environment.security.context}/jwt/protected/refresh`,
    };
  }
}
