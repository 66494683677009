import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericService} from './generic.service';
import {PublishModel} from '../model/publish.model';

@Injectable({
  providedIn: 'root',
})
export class PublishService extends GenericService<PublishModel> {
  constructor(httpClient: HttpClient) {
    super(httpClient, 'publish');
  }
}
