<div class="row">
  <div class="form-group col-sm-12">
    <label for="publishingDate" class="label form-control-label">
      Data pubblicazione:
    </label>

    <input nbInput placeholder="Data pubblicazione"
           id="publishingDate"
           readonly
           [nbDatepicker]="dateTimePicker"
           [(ngModel)]="this.publishingDate">
    <nb-date-timepicker #dateTimePicker
                        format="dd/MM/yyyy HH:mm"
                        [min]="this.minPublishingDate"
    ></nb-date-timepicker>
  </div>

  <nb-card *ngFor="let publishData of this.publishModel.publishDataList" class="form-group col-sm-12">
    <nb-card-body>
      <label class="label title form-control-label">
        {{ ForegroundTypeEnum[publishData.foregroundType] }}:
      </label>

      <div class="form-group">
        <nb-checkbox *ngFor="let data of publishData.dataList"
                     [name]="publishData.foregroundType + '_' + data.start"
                     [(ngModel)]="data.publish"
                     [disabled]="disabled(data)"
                     [checked]="checked(data)"
        >
          {{ moment(data.start).format('L') }} {{ data.end? ' - ' + moment(data.end).format('L') : ''}}
        </nb-checkbox>
      </div>
    </nb-card-body>
  </nb-card>

  <div class="col-sm-12">
    <div class="buttons-container text-right">
      <button nbButton matRipple status="success" (click)="saveData($event)">
        Salva
      </button>
      <button nbButton matRipple status="danger" (click)="reset($event)">
        Annulla
      </button>
    </div>
  </div>
</div>
