import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {OidcAuthService} from '../service/oidc.auth.service';
import {catchError, filter, switchMap, take} from 'rxjs/operators';

@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {

    constructor(
      /*private oidcSecurityService: OidcSecurityService*/
      private oidcAuthService: OidcAuthService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (
        req.url.startsWith(environment.api.domain) ||
        req.url.startsWith(environment.security.domain)
      ) {
        // return from(this.oidcAuthService.isAuthenticated())
        //   .pipe(
        //     switchMap(isAuthenticated => {
        //       if (!isAuthenticated) {
        //         this.oidcAuthService.completeAuthentication()
        //           .then(value => {
        //             return this.executeCall(req, next);
        //           });
        //       } else {
        //         return this.executeCall(req, next);
        //       }
        //     }),
        //   );

        // return from(this.oidcAuthService.getUser())
        //   .pipe(
        //     switchMap(user => {
        //       // if (user.expired) {
        //       //   this.oidcAuthService.completeAuthentication();
        //       // }
        //
        //       const token = user.access_token;
        //
        //       if (token) {
        //         const cloned = req.clone({
        //           headers: req.headers.set('Authorization', `Bearer ${token}`),
        //         });
        //         return next.handle(cloned);
        //       }
        //       return next.handle(req);
        //     }),
        //   );


        return from(this.oidcAuthService.getUser())
          .pipe(
            switchMap(user => {
              // if (user.expired) {
              //   this.oidcAuthService.completeAuthentication();
              // }

              const token = user.access_token;

              if (token) {
                const cloned = req.clone({
                  headers: req.headers.set('Authorization', `Bearer ${token}`),
                });

                return next.handle(cloned)
                  .pipe(
                    catchError(response => {
                      if (response) {
                        if (response instanceof HttpErrorResponse) {
                          if (response.status === 401 || response.status === 403) {
                            return this.handle401Error(req, next);
                          }
                          if (response.status === 403) {
                            // this.reindirizzamentoService.vaiAllaPaginaNonAutorizzato();
                            // return throwError(() => (response as unknown as HttpEvent<unknown>));
                          }
                        }
                      }
                      return throwError(() => (response as HttpEvent<unknown>));
                    }),
                  );
              }
              return next.handle(req);
            }),
          );
      }
    }

    private handle401Error(request: HttpRequest<unknown>, next: HttpHandler) {
      this.oidcAuthService.checkSession();
      return this.oidcAuthService.refreshTokenSubject.pipe(
        filter(token => token !== ''),
        take(1),
        switchMap(() => {
          return next.handle(this.addTokenHeader(request, this.oidcAuthService.authenticationToken()));
        }),
      );
    }

    private addTokenHeader(request: HttpRequest<unknown>, token: string) {
      return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }

    private executeCall(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return from(this.oidcAuthService.getUser())
        .pipe(
          switchMap(user => {
            const token = user.access_token;

            if (token) {
              const cloned = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`),
              });
              return next.handle(cloned);
            }
            return next.handle(req);
          }),
        );
    }
}
