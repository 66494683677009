import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ServiceUtils} from '../utils/service.utils';

export class GenericService<T> {
  constructor(protected httpClient: HttpClient, protected serviceName: string) {}

  findOne(): Observable<T> {
    return this.httpClient.get<T>(ServiceUtils.getApiUrl(this.serviceName));
  }

  findAll(): Observable<T[]> {
    return this.httpClient.get<T[]>(ServiceUtils.getApiUrl(this.serviceName));
  }
  findById(id: any): Observable<T> {
    return this.httpClient.get<T>(ServiceUtils.getApiUrl(this.serviceName, [id]));
  }
  create(obj: any): Observable<T> {
    return this.httpClient.post<T>(ServiceUtils.getApiUrl(this.serviceName), obj);
  }
  update(id: any, obj: any): Observable<T> {
    return this.httpClient.put<T>(ServiceUtils.getApiUrl(this.serviceName, [id]), obj);
  }
  delete(id: any): Observable<T> {
    return this.httpClient.delete<T>(ServiceUtils.getApiUrl(this.serviceName, [id]));
  }
}
